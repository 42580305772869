@font-face{
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNovaCond-Light.otf') format('opentype');
  font-weight: 100;
}

@font-face{
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face{
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face{
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face{
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova-Semibold.otf') format('opentype');
  font-weight: 600;
}

@font-face{
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face{
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova-Black.otf') format('opentype');
  font-weight: 900;
}

@font-face{
  font-family: 'Gotham';
  src: url('./../fonts/Gotham-Thin.otf') format('opentype');
  font-weight: 100;
}

@font-face{
  font-family: 'Gotham';
  src: url('./../fonts/gotham-book.ttf') format('truetype');
  font-weight: 400;
}

@font-face{
  font-family: 'Gotham';
  src: url('./../fonts/gothambold1.ttf') format('truetype');
  font-weight: 800;
}

@font-face{
  font-family: 'HelveticaNeue-Condensed';
  src: url('./../fonts/HelveticaNeue-CondensedMedium.ttf') format('truetype');
  font-weight: 400;
}

@font-face{
  font-family: 'HelveticaNeue';
  src: url('./../fonts/HelveticaNeue-UltraLight.ttf') format('opentype');
  font-weight: 100;
}

@font-face{
  font-family: 'HelveticaNeue';
  src: url('./../fonts/HelveticaNeue-UltraLightItalic.ttf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face{
  font-family: 'HelveticaNeue';
  src: url('./../fonts/HelveticaNeue-Light.ttf') format('opentype');
  font-weight: 300;
}

@font-face{
  font-family: 'HelveticaNeue';
  src: url('./../fonts/HelveticaNeue-LightItalic.ttf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face{
  font-family: 'HelveticaNeue';
  src: url('./../fonts/HelveticaNeue.ttf') format('opentype');
  font-weight: 400;
}

@font-face{
  font-family: 'HelveticaNeue';
  src: url('./../fonts/HelveticaNeue-Medium.ttf') format('opentype');
  font-weight: 600;
}


@font-face{
  font-family: 'HelveticaNeue';
  src: url('./../fonts/HelveticaNeue-MediumItalic.ttf') format('opentype');
  font-weight: 600;
  font-style: italic;
}